<template>
  <v-text-field
    type="text"
    prefix="$"
    :label="label"
    :readonly="readonly"
    :hint="hint"
    :rules="rules"
    v-model="displayValue"
    @input="formatInput"
    @blur="finalizeFormatting"
    @keydown="restrictInput"
    :persistent-hint="persistent"
    ><template v-slot:message="{ message }">
      <span :style="message == 'Opcional' ? 'color: #666666' : ''">
        {{ message }}
      </span>
    </template></v-text-field
  >
</template>

<script>
export default {
  props: {
    persistent: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Ingrese Importe",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    value: {
      // Prop para manejar el valor desde el padre
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      displayValue: this.formatValue(this.value),
      rawValue: "",
    };
  },
  watch: {
    value(newValue) {
      this.displayValue = this.formatValue(newValue);
    },
  },
  methods: {
    formatValue(value) {
      if (!value) return "";
      return new Intl.NumberFormat("es-AR", {
        currency: "ARS",
        minimumFractionDigits: 2,
      }).format(value / 100);
    },
    formatInput() {
      this.rawValue = this.displayValue.replace(/[^\d]/g, "");

      if (this.rawValue) {
        this.displayValue = this.formatValue(this.rawValue);
      } else {
        this.displayValue = ""; // Dejar en blanco si no hay valor
      }
      this.$emit("input", this.rawValue ? this.rawValue : null);
    },
    finalizeFormatting() {
      if (this.rawValue) {
        this.displayValue = this.formatValue(this.rawValue);
      } else {
        this.displayValue = ""; // Dejar en blanco si no hay valor
      }
      this.$emit("input-change", this.rawValue ? this.rawValue : null);
    },
    restrictInput(event) {
      const allowedKeys = [
        "Backspace",
        "ArrowLeft",
        "ArrowRight",
        "Delete",
        "Tab",
      ];
      if (!allowedKeys.includes(event.key) && !/^\d$/.test(event.key)) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
}
</style>
