<template>
  <layout-page title="Generar link de pago">
    <div class="mx-5 my-2">
      <h5 class="text-start mt-3 font-bold">Información de pago</h5>

      <!-- Formulario -->
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="payer_name"
              :rules="nameRules"
              label="Nombre"
              clearable
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="payer_email"
              :rules="emailRules"
              label="Correo"
              prepend-inner-icon="mdi-email-outline"
              placeholder="correo@correo.com"
              clearable
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="description"
              :counter="150"
              :rules="descriptionRules"
              label="Descripción"
              clearable
            >
            </v-text-field
          ></v-col>
        </v-row>

        <v-row>
          <v-expand-x-transition>
            <v-col cols="12" md="3" v-if="!agregarItem">
              <!-- Mostrar solo item.amount[0] si agregarItem es false -->
              <div>
                <FormattedInputGenerico
                  :value="items[0].amount"
                  @input-change="
                    (i) => {
                      items[0].amount = i;
                      calcularMonto();
                    }
                  "
                  :label="'Monto'"
                  :persistent="false"
                  :rules="amountRules"
                />
              </div>
            </v-col>
          </v-expand-x-transition>
          <v-col cols="12" md="3">
            <v-select
              v-model="selectedOptions"
              :items="opciones"
              :menu-props="{ right: true, offsetX: true }"
              prepend-inner-icon="mdi-form-select"
              label="Agregar"
              multiple
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @mousedown.prevent @click="toggle">
                  <v-list-item-action>
                    <v-icon
                      :color="
                        selectedOptions.length > 0 ? 'indigo darken-4' : ''
                      "
                    >
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      TODO
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:append-item>
                <v-divider class="mb-2"></v-divider>
                <v-list-item disabled>
                  <v-list-item-avatar color="grey lighten-3">
                    <v-icon>
                      mdi-form-dropdown
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content v-if="likeAllOptions">
                    <v-list-item-title>
                      Has Seleccionado todas las opciones!
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-content v-else-if="likeSomeOptions">
                    <v-list-item-title>
                      Cantidad seleccionadas
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ selectedOptions.length }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-content v-else>
                    <v-list-item-title>
                      No hay opciones agregadas
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Seleccione alguna opción
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <!-- <v-divider></v-divider> -->

        <!-- Referencia -->
        <v-expand-transition>
          <v-row v-if="refer">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="referencia"
                :counter="50"
                :rules="referenceRules"
                label="Referencia"
                clearable
              >
                Referencia
              </v-text-field></v-col
            >
          </v-row>
        </v-expand-transition>

        <!-- Items con montos -->
        <v-expand-transition>
          <!-- Mostrar todos los items y el botón de agregar si agregarItem es true -->
          <div class="my-2" v-if="agregarItem">
            <h4 class="text-start mb-3">Items</h4>

            <v-row v-for="(item, i) in items" :key="i" class="ml-2">
              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  v-model="item.quantity"
                  :rules="quantityRules"
                  label="Cantidad"
                  type="number"
                  @input="calcularMonto()"
                />
              </v-col>
              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  v-model="item.identifier"
                  class="textfield"
                  label="Identificador"
                  hint="Opcional"
                  persistent-hint
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  v-model="item.description"
                  :counter="150"
                  class="textfield"
                  label="Descripción"
                  clearable
                  hint="Opcional"
                  persistent-hint
                />
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <div class="d-flex align-center">
                  <div class="w-full">
                    <FormattedInputGenerico
                      :value="item.amount"
                      @input-change="
                        (i) => {
                          item.amount = i;
                          calcularMonto();
                        }
                      "
                      :label="'Monto'"
                      :persistent="false"
                      :rules="amountRules"
                    />
                  </div>

                  <v-tooltip top blue>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        right
                        icon
                        class="red lighten-5 ml-3"
                        :style="i === 0 ? 'visibility: hidden' : ''"
                        @click="
                          () => {
                            items.splice(i, 1);
                            calcularMonto();
                          }
                        "
                        v-on="on"
                        tile
                      >
                        <v-icon color="red lighten-2">mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Quitar item</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <div class="d-flex ml-4">
                <v-btn
                  class="cyan mb-5 font-weight-bold"
                  color="white"
                  text
                  rounded
                  tile
                  @click="agregarDetalle"
                >
                  <v-icon>mdi-plus</v-icon>
                  agregar item
                </v-btn>
              </div>
            </v-row>
          </div>
        </v-expand-transition>

        <!-- Vencimientos -->
        <v-expand-transition>
          <div class="my-3 shrink" v-if="totales">
            <h5 class="text-start mb-2">Vencimientos</h5>
            <v-row>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(first_due_date)"
                      label="Primer vecimiento"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="first_due_date"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3">
                <!-- Primer Total -->
                <FormattedInputGenerico
                  @input-change="(i) => (first_total = i)"
                  :value="first_total"
                  :label="'Primer Total'"
                  :readonly="true"
                  :persistent="false"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(second_due_date)"
                      label="Segundo vencimiento"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hint="Opcional"
                      clearable
                      persistent-hint
                      class="textfield"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="second_due_date"
                    @input="menu2 = false"
                    :min="getNextDay(first_due_date)"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="3">
                <!-- Segundo Total -->
                <FormattedInputGenerico
                  @input-change="(i) => (second_total = i)"
                  :value="second_total"
                  :rules="secondTotalRules"
                  :label="'Segundo Total'"
                  :persistent="true"
                  :readonly="false"
                  :hint="'Opcional'"
                />
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </v-form>
      <v-btn
        class="primary mt-12 mb-5 font-weight-bold text-subtitle-1"
        style="font-family: 'Montserrat' !important;"
        text
        large
        :loading="loading"
        :disabled="!formValid"
        @click="activarModal"
      >
        Generar pago
      </v-btn>

      <!-- Modal de Pago Generado -->
      <v-dialog v-model="dialog" width="600" persistent>
        <v-card>
          <div class="pa-6">
            <h5
              class="mb-0 text-start font-weight-bold"
              style="font-size: 20px;"
            >
              Pago generado
            </h5>

            <v-divider></v-divider>

            <h5 class="mb-1 text-start mt-5">Link de pago:</h5>
            <div class="d-flex" style="gap: 15px;">
              <v-text-field dense filled disabled v-model="urlPago">
              </v-text-field>

              <v-tooltip top blue>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    large
                    v-bind="attrs"
                    v-on="on"
                    @click="copiarPortapapeles()"
                  >
                    <v-icon style="font-size: 20px;">
                      mdi-content-copy
                    </v-icon>
                  </v-btn>
                </template>
                <span>Copiar link</span>
              </v-tooltip>
            </div>

            <!-- <v-alert outlined text type="info" v-if="!totales"
              ><h5 class="font-bold">
                Primer Vencimiento será el dia de hoy
              </h5></v-alert
            > -->
            <h5 class="mb-0 mb-1 text-start mt-3">Compartir por:</h5>
            <v-row>
              <v-col>
                <v-btn
                  block
                  outlined
                  color="green darken-3"
                  @click="enviarWhatsap()"
                >
                  <v-icon style="cursor: pointer !important;" class="mr-2">
                    mdi-whatsapp
                  </v-icon>
                  Whatsapp
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  block
                  outlined
                  color="blue darken-3"
                  @click="enviarEmail()"
                >
                  <v-icon style="cursor: pointer !important;" class="mr-2">
                    mdi-email-outline
                  </v-icon>
                  Correo
                </v-btn>
              </v-col>
            </v-row>

            <div class="mt-10 mb-6">
              <v-divider></v-divider>
            </div>
            <v-row class="pa-2">
              <v-spacer></v-spacer>

              <v-btn
                class="grey lighten-3 px-10"
                elevation="0"
                rounded
                @click="cerrarDialog()"
              >
                Cerrar
              </v-btn>
            </v-row>
          </div>
        </v-card>
      </v-dialog>

      <!-- Modal para confirmar Datos a enviar -->

      <v-dialog v-model="dialogConfirmacion" persistent max-width="850">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2 mb-5">
            <div>
              Confirmación de pago
            </div>
            <!-- <div class="flex justify-end">
              <v-icon color="red" large>mdi-message-alert-outline</v-icon>
            </div> -->
          </v-card-title>
          <v-card-text class="my-auto mx-auto">
            <div class="mx-4 text-black">
              <!-- Nombre -->
              <v-row class="my-2"
                ><h1 class="text-start">
                  <strong>Nombre:</strong> {{ payer_name }}
                </h1></v-row
              >
              <!-- Correo -->
              <v-row class="my-2"
                ><h1 class="text-start">
                  <strong>Correo:</strong> {{ payer_email }}
                </h1></v-row
              >
              <!-- Descripcion -->
              <v-row class="my-2"
                ><h1 class="text-start">
                  <strong>Descripción de Pago:</strong> {{ description }}
                </h1>
              </v-row>
              <v-row class="my-2" v-if="refer">
                <h1 class="text-start">
                  <strong>Referencia: </strong>
                  {{ external_reference }}
                </h1>
              </v-row>
              <!-- Primer total -->
            </div>
            <!-- Items -->
            <v-row>
              <v-col>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">
                          <h1>
                            Cantidad
                          </h1>
                        </th>
                        <th class="text-center">
                          <h1>
                            Identificador
                          </h1>
                        </th>
                        <th class="text-center">
                          <h1>
                            Descripción
                          </h1>
                        </th>
                        <th class="text-center">
                          <h1>
                            Monto
                          </h1>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, i) in items"
                        :key="i"
                        :class="{ 'grey lighten-3': i % 2 !== 0 }"
                      >
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.identifier ? item.identifier : "-" }}</td>
                        <td>{{ item.description ? item.description : "-" }}</td>
                        <td>{{ formatImport(item.amount) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>

            <!-- Vencimientos -->
            <div class="flex justify-content-end">
              <v-col cols="0" md="7"></v-col>
              <v-col cols="12" md="6">
                <v-row class="text-black" justify="end">
                  <!-- Primer Vencimiento -->
                  <v-col cols="8" md="8" class="text-right ">
                    <strong>Primer vencimiento:</strong>
                    {{ moment(first_due_date).format("DD/MM/YYYY") }}
                  </v-col>
                  <v-col cols="4" md="4" class="text-right">
                    <strong>Total:</strong>
                    {{ formatImport(first_total) }}
                  </v-col>

                  <!-- Segundo Vencimiento -->
                  <v-col
                    cols="8"
                    md="8"
                    class="text-right"
                    v-if="second_total !== ''"
                  >
                    <strong>Segundo vencimiento:</strong>
                    {{ moment(first_due_date).format("DD/MM/YYYY") }}
                    <!-- {{ moment(second_due_date).format("DD/MM/YYYY") }} -->
                  </v-col>
                  <v-col
                    cols="4"
                    md="4"
                    class="text-right"
                    v-if="second_total !== ''"
                  >
                    <strong>Total:</strong>
                    {{ formatImport(second_total) }}
                  </v-col>
                </v-row>
              </v-col>
            </div>
          </v-card-text>
          <v-card-actions class="my-4">
            <v-spacer></v-spacer>

            <div class="flex gap-x-10">
              <v-btn
                color="red darken-1"
                class="text-white"
                @click="dialogConfirmacion = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="green darken-1"
                class="text-white"
                @click="generarCheckout()"
              >
                Confirmar
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </layout-page>
</template>

<script>
//Componente de formateo Visualmente de Números a Pesos con signo $
import FormattedInputGenerico from "./FormattedInputGenerico";
//Libreria de formateo de fecha
import moment from "moment";
import LayoutPage from "../../components/LayoutPage.vue";
import { mapGetters } from "vuex";
import checkoutService from "../../services/global/checkout";
//Formateo de fecha e importe
import formatDate from "../../utils/formatDate";
import formatImport from "../../utils/formatImport";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

//Libreria de formateo de números a Pesos $
const currencyMask = createNumberMask({
  prefix: "$",
  allowDecimal: true,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  decimalSymbol: ",",
  allowNegative: false,
});

export default {
  components: {
    FormattedInputGenerico,
    LayoutPage,
  },
  comments: { LayoutPage },
  data() {
    return {
      formValid: true,
      valid: false,
      payer_name: "",
      description: "",
      first_total: null,
      first_due_date: moment().format("YYYY-MM-DD"),
      external_reference: "",
      referencia: "",

      descr: false,
      descItem: false,

      second_due_date: null,
      second_total: "",
      payer_email: "",

      confirmar: false,

      expiration: 0,

      items: [
        {
          quantity: 1,
          description: "",
          amount: null,
          identifier: "",
        },
      ],

      monto: null,

      menu: false,
      menu2: false,

      loading: false,
      dialog: false,

      urlPago: "",

      // rules
      nameRules: [(v) => !!v || "El nombre es requerido"],
      emailRules: [
        (v) => !!v || "El correo es requerido",
        (v) => /.+@.+\..+/.test(v) || "Correo inválido",
      ],

      secondTotalRules: [
        (v) => {
          if (!v) return true; // Si el campo está vacío, la validación pasa
          return (
            this.parseAmount(this.cleanNumber(v)) >
              this.parseAmount(this.cleanNumber(this.first_total)) ||
            "El segundo total debe ser mayor que el primero"
          );
        },
      ],

      identifierRules: [(v) => !!v || "El identificador es requerido"],

      amountRules: [(v) => !!v || "El monto es requerido"],
      quantityRules: [
        (v) => !!v || "La cantidad es requerida",
        (v) => v >= 1 || "La cantidad no puede ser menor a 1",
      ],
      descRules: [
        (v) => !!v || "La descripción es requerida",
        (v) =>
          (v && v.length <= 100) ||
          "La descripción debe tener menos de 150 caracteres",
      ],

      referenceRules: [
        (v) => !!v || "La referencia es requerida",
        (v) =>
          (v && v.length <= 50) ||
          "La descripción debe tener menos de 50 caracteres",
      ],

      descriptionRules: [
        (v) => !!v || "La descripción es requerida",
        (v) =>
          (v && v.length <= 100) ||
          "La descripción debe tener menos de 150 caracteres",
      ],

      formatDate,
      currencyMask,

      opciones: ["Referencia", "Items", "Vencimientos"],
      selectedOptions: [],
      dialogConfirmacion: false, //false
    };
  },

  //Formato peso Argentino - No se esta usando -
  filters: {
    formatAmountARS(value) {
      return new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
      }).format(value);
    },
  },

  computed: {
    ...mapGetters(["CLIENTE_CUENTA_DATA", "getCurrentAccountId"]),

    likeAllOptions() {
      return this.selectedOptions.length === this.opciones.length;
    },
    likeSomeOptions() {
      return this.selectedOptions.length > 0 && !this.likeAllOptions;
    },
    icon() {
      if (this.likeAllOptions) return "mdi-close-box";
      if (this.likeSomeOptions) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

    //Para manejar los datos a mostrar
    agregarItem() {
      return this.selectedOptions.includes("Items");
    },
    totales() {
      return this.selectedOptions.includes("Vencimientos");
    },
    refer() {
      return this.selectedOptions.includes("Referencia");
    },
  },

  watch: {
    // Observar los cambios en selectedOptions
    selectedOptions(newVal) {
      // Verificar si "Items" no está en las opciones seleccionadas
      if (!newVal.includes("Items")) {
        // Eliminar todos los elementos excepto el primero
        this.items = this.items.slice(0, 1);
      }
      this.calcularMonto();
    },
  },

  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likeAllOptions) {
          this.selectedOptions = [];
        } else {
          this.selectedOptions = this.opciones.slice();
        }
      });
    },

    //Limpiar numero, saca el signo $, comas y puntos para poder sumar valores enteros
    cleanNumber(value) {
      if (typeof value === "string") {
        return parseFloat(value.replace(/[$,.]/g, ""));
      }
      return value;
    },
    formatImport,

    // Para manejar el data-picker de la segunda fecha de vencimiento no sea menor o igual a la primra fecha
    getNextDay(date) {
      const nextDay = new Date(date);
      nextDay.setDate(nextDay.getDate() + 1); // Suma un día
      return nextDay.toISOString().substr(0, 10); // Devuelve la fecha en formato YYYY-MM-DD
    },

    async activarModal() {
      if (this.$refs.form.validate()) {
        this.dialogConfirmacion = true;
      }
    },

    //Función de Generar Pago
    async generarCheckout() {
      if (this.dialogConfirmacion) {
        this.dialogConfirmacion = false;
      }
      const { apikey, tipos_pago } = this.CLIENTE_CUENTA_DATA.cuenta.find(
        (c) => c.id === this.getCurrentAccountId
      );

      const timestamp = moment()
        .unix()
        .toString();

      if (this.$refs.form.validate()) {
        const items = this.items.map((item) => {
          return {
            ...item,
            amount: this.parseAmount(this.cleanNumber(item.amount)),
            quantity: Number(item.quantity),
          };
        });

        if (!this.totales) {
          this.first_due_date = moment().format("YYYY-MM-DD");
        }

        const body = {
          payer_name: this.payer_name,
          description: this.description,
          first_total: this.parseAmount(this.first_total),
          second_total: this.second_total
            ? this.parseAmount(this.second_total)
            : 0,
          first_due_date: moment(this.first_due_date).format("DD-MM-YYYY"),
          second_due_date: this.second_due_date
            ? moment(this.second_due_date).format("DD-MM-YYYY")
            : "",
          external_reference: this.refer
            ? this.referencia
            : `wee${timestamp.substring(3)}`,
          payer_email: this.payer_email,
          payment_type: tipos_pago[0].PagoTipo,
          expiration: 0,
          items,
        };

        try {
          this.loading = true;
          const { data } = await checkoutService.generarCheckout(
            body,
            window.atob(apikey)
          );

          this.dialog = true;
          this.urlPago = data.checkout_url;
          this.$toastr.s("Pago generado con éxito");
        } catch (e) {
          this.$toastr.e("Servicio de pagos, momentáneamente no disponible");
          // console.error(e);
        } finally {
          this.loading = false;
        }
      }
    },

    enviarWhatsap() {
      window.open("https://wa.me/?text=" + this.urlPago, "_blank");
    },
    enviarEmail() {
      const message = "mailto:?subject=Pagos&body=" + this.urlPago;
      window.open(message, "_blank");
    },
    copiarPortapapeles() {
      // Se necesita hacer esto para acceder dentro de la funcion a la data del component

      navigator.clipboard.writeText(this.urlPago).then(
        () => this.$toastr.s("Enlace copiado con éxito."),
        () => this.$toastr.e("No se pudo copiar el enlace.")
      );
    },

    //Agregar más Items
    agregarDetalle() {
      this.items.push({
        quantity: 1,
        description: "",
        amount: null,
        identifier: "",
      });
    },

    //Calcular el monto total
    calcularMonto() {
      this.first_total = this.items.reduce((total, item) => {
        const amount = this.cleanNumber(item.amount) || 0;
        const quantity = item.quantity || 0;
        return total + amount * quantity;
      }, 0);
    },

    //Cerrar Dialogo de Pago Generado
    async cerrarDialog() {
      const { isConfirmed } = await this.$swal.fire({
        title: "¿Está seguro?",
        icon: "info",
        showDenyButton: true,
        confirmButtonText: "Si, continuar",
        denyButtonText: "Cancelar",
        confirmButtonColor: "#4CAF50",
        cancelButtonColor: "#f44336",
      });

      if (isConfirmed) {
        this.dialog = false;

        // reset form
        this.payer_name = "";
        this.payer_email = "";
        this.description = "";
        this.items = [
          {
            quantity: 1,
            description: "",
            amount: null,
            identifier: "",
          },
        ];

        this.first_total = null;
        this.second_total = null;

        this.$refs.form.resetValidation();
      }
    },

    parseAmount(monto) {
      monto = monto + "";
      let [entero, decimales] = monto.includes(".")
        ? monto.split(".")
        : monto.split(",");

      // Si hay parte decimal, la concatenamos sin modificar
      const total = decimales ? entero + decimales : entero;

      return Number(total);
    },
  },
};
</script>

<style lang="css" scoped>
.textfield ::v-deep.v-messages__message {
  color: #666 !important;
}

.text-field-mask {
  border-bottom: 1px solid #777;
  min-height: 38px;
}
</style>
